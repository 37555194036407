/******* screen-medium.less *******/
/*
brakpoints chosen after font-sizes, areas change at different breakpoints
*/
/******* mixins.less 2013-1-16 *******/
/******* layout-medium.less 2013-1-16 *******/
#home {
  margin-left: 4px;
  width: 42.85714286%;
  max-width: 180px;
  position: relative;
  z-index: 1;
}
.mood--large {
  background-attachment: fixed;
}
.desk {
  width: 70%;
}
.desk--wide.desk--wide {
  width: auto;
  margin-left: 30px;
  margin-right: 30px;
}
.mood--small {
  background: none !important;
}
.cb-layout1 #head {
  margin-top: 88px;
}
.cb-layout2 #head {
  width: 85.71428571%;
  margin-left: 7.14285714%;
}
.cb-layout2 .mood--small {
  padding-bottom: 0;
}
.north-wrapper {
  background: none;
  padding: 0;
  margin-top: 29px;
}
.content-half,
.footer {
  margin-top: 80px;
}
/*.cb-layout2 .navigation {
  
  align with content
  
  top: 84px;
  position: relative;
}*/
.maincontent {
  margin-top: 89px;
}
.footer {
  padding-top: 25px;
}
.social {
  margin-top: 10px;
}
.navigation.navigation.navigation {
  position: absolute;
  top: 243px;
  right: 0;
  left: 0;
}
div.sub2 {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
}
.navigation .sub2 {
  height: 0;
}
div.sub2 > .item {
  width: 25%;
  padding: 0 15px;
  box-sizing: border-box;
}
.sub2 > .item > .menu {
  padding-top: 0.4em;
}
.sub2 + .service_member {
  display: none;
}
.service_member,
.topnav .meta.auth {
  float: left;
  font-size: 18px;
  font-size: 1.8rem;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 200;
  letter-spacing: 0.016em;
  line-height: 50px;
  margin-right: 1.4em;
}
.service_member:hover,
.service_member:focus {
  color: #ff906c;
}
div.sub3 .menu.menu {
  padding: 0.25em 0;
}
html.cb-toggle-target-active .mood--large:after {
  background: rgba(0, 0, 0, 0.8);
}
.main,
.side {
  width: 100%;
}
.main > .unit,
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.main h2,
.side h2,
.main .foot,
.side .foot {
  margin-right: 7.14285714%;
  margin-left: 7.14285714%;
}
.main .part,
.side .part,
.main > .grid table,
.side > .grid table {
  margin-right: 7.14285714%;
  margin-left: 7.14285714%;
  width: 85.71428571%;
}
.main > .slim .part,
.side > .slim .part,
.main > .slim.grid table,
.side > .slim.grid table {
  width: 85.71428571%;
}
.north > .unit {
  box-sizing: border-box;
  padding: 0 12.5px;
  width: 50%;
  margin-left: 0;
  margin-right: 0;
}
div.main {
  padding-bottom: 40px;
}
.north {
  margin-bottom: -25px;
  width: calc(100% + 25px);
  margin-right: -12.5px;
  margin-left: -12.5px;
}
.cb-layout2 .main {
  margin-top: 43px;
}
/*.cb-layout1 .main .pure:first-child .body {
  margin-top: 79px;
}*/
.content .unit.unit:last-child {
  margin-bottom: 84px;
}
/*.cb-layout2 .main .pure:first-child h2 {
  margin-top: 30px;
}*/
.cb-layout2 .main > .unit {
  margin-top: 30px;
  margin-bottom: 30px;
}
.cb-layout2 .main > .unit.fold,
.cb-layout2 .main > .unit.flat {
  margin-top: 10px;
  margin-bottom: 10px;
}
.cb-layout2 .main > .unit.fold:last-child,
.cb-layout2 .main > .unit.flat:last-child {
  margin-bottom: 30px;
}
/*.side > .unit:last-child {
  margin-bottom: 84px;
}*/
.content .link > .open {
  text-align: left;
  display: inline-block;
}
.north > .unit {
  display: inline-block;
  float: none;
  vertical-align: top;
  margin-bottom: 25px;
}
.north .link {
  margin: 0;
}
.north .link > .open {
  min-height: 2.5em;
  font-size: 30px;
  font-size: 3rem;
  hyphens: auto;
  letter-spacing: normal;
  padding: 0.31em 0.3em 0.5em 0.5em;
}
.fold h2 .fold-toggle:after {
  left: 7.14285714%;
}
.side .pure + .seam {
  /*
  veranstaltung
  */
  margin-top: 55px;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
/*.cb-layout2 h1,
.unit h2 {
  .hyphens(none);
}*/
/*# sourceMappingURL=./screen-medium.css.map */